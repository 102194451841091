<template>
  <div>
    <v-dialog
      :value="dialog"
      width="616"
      persistent
      style="z-index: 200013"
      class="import-modal"
    >
      <v-card class="pa-5">
        <v-card-title class="text-h5 lighten-2 pa-0 mb-1">Import</v-card-title>

        <p class="text-h7 mb-3">The maximum file size is 50 MB, and accepted formats are CSV, XLS, or XLSX.</p>

        <v-form>
          <v-select
            v-model="serviceProvider"
            :items="providerSelectOptions"
            :menu-props="{ bottom: true, offsetY: true }"
            item-text="name"
            item-value="name"
            label="Service Provider"
            class="mb-5"
            hide-details
            clearable
            outlined
            @blur="$v.serviceProvider.$touch()"
          />

          <file-input-area
            :value="file"
            :error-messages="fileErrors"
            @change="handleFileChange"
            @blur="$v.file.$touch()"
          />
        </v-form>

        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn
            text
            color="primary"
            :disabled="isLoading"
            @click="toggleDialogView"
          >
            Cancel
          </v-btn>
          <v-btn
            :disabled="$v.$invalid"
            :loading="isLoading"
            color="primary"
            rounded
            normal
            variant="elevated"
            @click="handleModalSendClick"
          >
            Import
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapWritableState } from "pinia";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { getProviderNames } from "@/services/cases";
import { useCasesImportStore } from "@/stores/casesImport";
import FileInputArea from "../controls/FileInputArea.vue";

export default {
  name: "ImportCasesModal",

  components: {
    FileInputArea,
  },

  mixins: [validationMixin],

  props: {
    dialog: {
      type: Boolean,
      required: () => false,
    },
  },

  data() {
    return {
      file: null,
      filename: "",
      fileTypes: [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/csv"
      ],
      isLoading: false,
      providerNames: null,
      serviceProvider: null,
    };
  },

  validations: {
    file: {
      required,
      type: function (value) {
        if (!value) return true;
        return this.fileTypes.includes(value.type);
      },
    },
    serviceProvider: { required },
  },

  computed: {
    ...mapWritableState(useCasesImportStore, ["isProgressModalVisible"]),
    fileErrors() {
      const errors = [];
      if (!this.$v.file.$dirty) return errors;
      !this.$v.file.required && errors.push("File is required");
      !this.$v.file.type && errors.push("The file type should be xls, csv or xlsx");
      return errors;
    },
    providerSelectOptions() {
      if (!this.providerNames?.length) return [];
      const baseOptions = this.providerNames.map((provider) => ({
        name: provider,
        disabled: false,
      }));
      return [
        ...baseOptions,
        {
          name: "SystmOne",
          disabled: true,
        },
      ];
    },
  },

  async mounted() {
    await this.getSelectOptions();
  },

  methods: {
    ...mapActions(useCasesImportStore, ["startCasesImport", "reset"]),
    toggleDialogView() {
      this.resetModalData();
      this.$emit("toggle", !this.dialog);
    },
    handleFileChange(file) {
      this.file = file;
      this.filename = file?.name || "";
      this.$v.file.$touch();
    },
    async getSelectOptions() {
      try {
        this.providerNames = await getProviderNames();
      } catch (e) {
        this.$notify({
          type: "error",
          title: "Get provider names error",
          text: e?.response?.data?.message || JSON.stringify(e),
        });
      }
    },
    async handleModalSendClick() {
      try {
        this.isLoading = true;
        await this.startCasesImport(this.serviceProvider, this.file);
        this.$notify({
          type: "success",
          title: "Cases import",
          text: "Cases import process started",
        });
        this.toggleDialogView();
      } catch (e) {
        this.reset();
        if (e?.response?.data?.error === "Cases Import Error") {
          this.$notify({
            type: "warning",
            title: "Cases import error",
            text: e?.response?.data?.message,
          });
        } else {
          this.$notify({
            type: "error",
            title: "Cases import error",
            text: e?.response?.data?.message || JSON.stringify(e),
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    resetModalData() {
      this.file = null;
      this.serviceProvider = null;
    },
  },
};
</script>
