<template>
  <v-main class="cases">
    <div class="app-container pt-10 pb-3">
      <section
        class="cases__header d-flex flex-row justify-space-between align-center mb-5"
      >
        <div class="cases__text">
          <h1 class="text-h4 font-weight-bold">Cases</h1>
          <p class="text-subtitle-1 text-medium-emphasis">
            Here you can view and score cases.
          </p>
        </div>

        <div class="d-flex justify-start align-center">
          <a
            v-if="!isMobile && isAdmin"
            class="cases__header__delete-btn d-flex align-center justify-center"
            :class="{
              'cases__header__delete-btn--disabled': !selectedCases.length,
            }"
            @click="onCaseRemove"
          >
            <v-icon color="black">mdi-delete</v-icon>
          </a>
          <a
            v-if="!isMobile"
            class="cases__header__filter-btn d-flex align-center justify-center"
            :class="{ 'cases__header__filter-btn--active': isFilterActive }"
            @click="onFilterClick"
          >
            <v-icon color="black">mdi-filter-variant</v-icon>
          </a>
          <v-badge
            v-else
            :value="filterBadgeContent"
            :content="filterBadgeContent"
            offset-x="15"
            offset-y="20"
            color="primary"
          >
            <a
              class="cases__header__filter-btn d-flex align-center justify-center"
              @click="onFilterClick"
            >
              <v-icon color="black">mdi-filter-variant</v-icon>
            </a>
          </v-badge>

          <v-btn
            v-if="isAdmin"
            :disabled="isImportInProgress"
            color="primary"
            rounded
            normal
            variant="elevated"
            class="ml-2"
            @click="startImport"
          >
            <v-icon left> mdi-download </v-icon>
            Import Cases
          </v-btn>
        </div>
      </section>

      <v-skeleton-loader
        v-if="firstLoading"
        :loading="loading"
        type="table"
        min-width="100%"
      />

      <section class="cases__table">
        <v-data-table
          v-show="!firstLoading"
          v-model="selectedCases"
          v-bind="isAdmin ? { showSelect: true } : {}"
          :loading="loading"
          :headers="headers"
          :items="fullCasesData"
          :options.sync="options"
          :server-items-length="totalCases"
          :item-class="itemRowBackground"
          :hide-default-footer="isMobile"
          :footer-props="tableFooterOptions"
          :mobile-breakpoint="0"
          multi-sort
          item-key="_id"
          @click:row="goToCase"
          @update:options="fetchCases"
        >
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template
            v-if="isFilterActive && !isMobile && filtersOptions"
            v-slot:top
          >
            <v-row class="d-lfex align-center mb-3">
              <v-col class="d-flex" cols="2">
                <search-input
                  :value="filters.caseReference"
                  label="Search Case Id+"
                  @change="handleFilterChange($event, 'caseReference')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <v-select
                  :value="filters.locationName"
                  :items="filtersOptions.locationName"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Location"
                  hide-details
                  clearable
                  outlined
                  class="case-status-select"
                  @change="handleFilterChange($event, 'locationName')"
                />
              </v-col>

              <v-col class="d-flex" cols="3">
                <time-data-picker
                  ref-name="menu1"
                  :date="filters.caseDate"
                  label="Case Date"
                  @change="handleFilterChange($event, 'caseDate')"
                />
              </v-col>

              <v-col class="d-flex" cols="2">
                <v-select
                  :value="filters.caseType"
                  :items="filtersOptions.caseType"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Case Type"
                  clearable
                  hide-details
                  outlined
                  @change="handleFilterChange($event, 'caseType')"
                />
              </v-col>
              <v-col class="d-flex" cols="2">
                <v-select
                  :value="filters.source"
                  :items="filtersOptions.source"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Source"
                  clearable
                  hide-details
                  outlined
                  @change="handleFilterChange($event, 'source')"
                />
              </v-col>
              <v-col class="d-flex" cols="2">
                <v-select
                  :value="filters.latestPriority"
                  :items="filtersOptions.latestPriority"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="Latest Priority"
                  clearable
                  hide-details
                  outlined
                  @change="handleFilterChange($event, 'latestPriority')"
                />
              </v-col>
              <v-col class="d-flex" cols="3">
                <ClinicalServicesMultiselect
                  :services="filters.clinicalService"
                  ref="servicesInput"
                  @change="handleFilterChange($event, 'clinicalService')"
                />
              </v-col>
              <v-col class="d-flex" cols="3">
                <time-data-picker
                  ref-name="menu2"
                  :date="filters.createdAt"
                  label="Import Date"
                  @change="handleFilterChange($event, 'createdAt')"
                />
              </v-col>
              <v-col class="d-flex justify-center ml-12" cols="1">
                <CancelButton @click="onFilterCancel" />
              </v-col>
              <v-col class="d-flex justify-center ml-16" cols="1">
                <ApplyButton @click="fetchCases" />
              </v-col>
            </v-row>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.clinicians="{ item }">
            <div class="d-flex flex-column flex-start align-start">
              <span v-if="item?.consultation?.clinicianId">
                {{ item.consultation.clinicianId?.name }}
              </span>
              <span v-else-if="item?.advice?.clinicianId">
                {{ item.advice.clinicianId?.name }}
              </span>
              <span v-else>-</span>
            </div>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.caseReference="{ item }">
            <span>{{ get(item, "caseReference", "-") }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.source="{ item }">
            <span>{{ get(item, "source", "-") }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.clinicalService.name="{ item }">
            <span>{{ get(item, "clinicalService.name", "-") }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.locationName="{ item }">
            <span>{{ get(item, "locationName", "-") }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.caseType="{ item }">
            <span>{{ get(item, "caseType", "-") }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.caseDate="{ item }">
            <span>{{ getDateForDisplay(item, "caseDate", false) }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.createdAt="{ item }">
            <span>{{ getDateForDisplay(item, "createdAt", false) }}</span>
          </template>

          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.latestPriority="{ item }">
            <v-chip v-if="item.latestPriority" outlined>
              {{ item.latestPriority }}
            </v-chip>
            <span v-else>-</span>
          </template>

          <template #progress>
            <v-progress-linear color="primary" indeterminate />
          </template>
        </v-data-table>

        <v-pagination
          v-if="isMobile"
          :value="options.page"
          :length="paginationLength"
          class="mt-3"
          @input="onPaginationChange"
        ></v-pagination>
      </section>

      <v-dialog
        v-if="isMobile && filtersOptions"
        v-model="isFilterActive"
        fullscreen
        persistent
      >
        <v-card flat class="cases__filter-menu">
          <div
            class="app-container pb-4 d-flex flex-column justify-space-between"
          >
            <div class="cases__filter-menu__filters pt-10">
              <h1 class="text-h4 font-weight-bold pb-5">Filters</h1>

              <search-input
                :value="filters.caseReference"
                label="Search Case Id+"
                @change="handleFilterChange($event, 'caseReference')"
              />
              <br />
              <v-select
                :value="filters.locationName"
                :items="filtersOptions.locationName"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Location"
                hide-details
                clearable
                outlined
                class="case-status-select"
                @change="handleFilterChange($event, 'locationName')"
              />
              <br />
              <time-data-picker
                ref-name="menu1"
                :date="filters.caseDate"
                label="Case Date"
                @change="handleFilterChange($event, 'caseDate')"
              />
              <br />
              <v-select
                :value="filters.caseType"
                :items="filtersOptions.caseType"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Case Type"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'caseType')"
              ></v-select>
              <br />
              <v-select
                :value="filters.latestPriority"
                :items="filtersOptions.latestPriority"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Latest Priority"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'latestPriority')"
              ></v-select>
              <br />
              <v-select
                :value="filters.source"
                :items="filtersOptions.source"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Source"
                clearable
                hide-details
                outlined
                @change="handleFilterChange($event, 'source')"
              ></v-select>
              <ClinicalServicesMultiselect
                :services="filters.clinicalService"
                ref="servicesInput"
                @change="handleFilterChange($event, 'clinicalService')"
              />
              <br />
              <time-data-picker
                ref-name="menu2"
                :date="filters.createdAt"
                label="Import Date"
                @change="handleFilterChange($event, 'createdAt')"
              />
            </div>

            <div class="cases__filter-menu__controls d-flex flex-column">
              <ApplyButton large class="mb-5" @click="fetchCases" />
              <CancelButton large @click="onFilterCancel" />
            </div>
          </div>
        </v-card>
      </v-dialog>

      <ImportCasesModal
        :dialog="importModal"
        @toggle="onImportModalToggle"
        @update="fetchCases"
      />

      <DeleteModal
        title="Delete cases"
        description="Are you sure you want to delete selected cases?"
        :is-opened="deleteCasesModal"
        :loading="deleteCasesLoading"
        @submit="onDeleteCases"
        @close="deleteCasesModal = false"
      />
    </div>
  </v-main>
</template>

<script>
import { mapState, mapActions } from "pinia";
import { getDateForDisplay } from "@/helpers/getDateForDisplay";
import { getCases, getSelectOptions, batchDeleteCases } from "@/services/cases";
import { getClinicalServices } from "@/services/clinicalServices";
import { useUserStore } from "@/stores/user";
import SearchInput from "@/components/controls/SearchInput.vue";
import TimeDataPicker from "@/components/controls/TimeDataPicker.vue";
import ImportCasesModal from "@/components/case/ImportCasesModal.vue";
import DeleteModal from "@/components/common/DeleteModal.vue";
import ApplyButton from "@/components/common/filterControls/ApplyButton.vue";
import CancelButton from "@/components/common/filterControls/CancelButton.vue";
import { TableRowsPerPage, CasesStatuses } from "@/misc/constants";
import { useCasesImportStore } from "../../stores/casesImport";
import ClinicalServicesMultiselect from "@/components/controls/ClinicalServicesMultiselect.vue";
import { get } from "@/helpers/get";

export default {
  name: "CasesPage",

  components: {
    ClinicalServicesMultiselect,
    SearchInput,
    TimeDataPicker,
    ImportCasesModal,
    DeleteModal,
    ApplyButton,
    CancelButton,
  },

  data() {
    const tableFooterOptions = {
      "items-per-page-options": TableRowsPerPage,
    };

    const headers = [
      {
        text: "Case Reference",
        align: "start",
        value: "caseReference",
        sortable: false,
      },
      {
        text: "Source",
        value: "source",
        sortable: false,
      },
      {
        text: "Service",
        value: "clinicalService.name",
        sortable: false,
      },
      {
        text: "Clinician Name",
        value: "clinicians",
        sortable: false,
      },
      {
        text: "Location",
        value: "locationName",
        sortable: false,
      },
      { text: "Case Date", value: "caseDate" },
      {
        text: "Case Type",
        value: "caseType",
        sortable: false,
      },
      {
        text: "Latest Priority",
        value: "latestPriority",
        sortable: false,
      },
      {
        text: "Import date",
        value: "createdAt",
      },
    ];

    return {
      loading: true,
      options: {},
      totalCases: null,
      tableFooterOptions,
      isFilterActive: false,
      headers,
      fullCasesData: [],
      filtersOptions: null,
      filters: {
        caseReference: null,
        locationName: null,
        caseType: null,
        latestPriority: null,
        caseDate: null,
        createdAt: null,
        clinicalService: null,
      },
      clinicalServices: [],
      selectedCases: [],
      importModal: false,
      deleteCasesModal: false,
      deleteCasesLoading: false,
      firstLoading: true,
    };
  },

  computed: {
    ...mapState(useUserStore, ["isAdmin"]),
    ...mapState(useCasesImportStore, ["isImportInProgress", "importProgress"]),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    filterBadgeContent() {
      return Object.values(this.filters).reduce(
        (acc, value) => acc + !!value,
        0
      );
    },
    paginationLength() {
      if (!this.options || !this.options.itemsPerPage) return 0;
      return Math.ceil(this.totalCases / this.options.itemsPerPage);
    },
  },

  watch: {
    importProgress(value) {
      if (value === 100) {
        this.fetchCases();
      }
    },
  },

  async mounted() {
    await Promise.all([this.fetchServices(), this.fetchFilterOptions()]);
  },

  methods: {
    get,
    getDateForDisplay,
    ...mapActions(useCasesImportStore, ["reset"]),
    startImport() {
      this.importModal = true;
      this.reset();
    },
    async fetchFilterOptions() {
      try {
        this.filtersOptions = await getSelectOptions(
          "locationName,latestPriority,caseType,source"
        );
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    async fetchCases() {
      try {
        this.loading = true;
        const query = this.buildQuery();
        const { data, total } = await getCases(query);
        this.totalCases = total;
        this.fullCasesData = data.map((item) => ({
          ...item,
          clinicalService: this.getServiceById(get(item, "clinicalService")),
        }));
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      } finally {
        this.loading = false;
        this.firstLoading = false;
      }
    },
    async fetchServices() {
      try {
        this.clinicalServices = await getClinicalServices();
      } catch (e) {
        this.$notify({
          type: "Error",
          text: e?.message || JSON.stringify(e),
        });
      }
    },
    buildQuery() {
      const queryParams = {};
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      queryParams.page = page || 1;
      queryParams.pageSize = itemsPerPage || 10;

      if (sortBy && sortDesc && sortBy.length && sortDesc.length) {
        const sort = sortBy.reduce((acc, el, ind) => {
          const sortBy = `${el}:${sortDesc[ind] ? -1 : 1}`;
          acc += ind === 0 ? sortBy : `,${sortBy}`;
          return acc;
        }, "");
        queryParams.sort = sort;
      }

      if (Object.values(this.filters).some((val) => !!val === true)) {
        const filters = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key]) {
            if (key === "caseDate" || key === "createdAt") {
              const [first, second] = this.filters[key];
              const startDate = new Date(first);
              const endDate = second
                ? new Date(second)
                : new Date(startDate.getTime() + 86400000);
              filters[key] = { $gte: startDate, $lt: endDate };
            } else if (key === "caseReference") {
              filters[key] = { $regex: this.filters[key] };
            } else if (key === "clinicalService") {
              filters[key] = { $in: this.filters[key] };
            } else {
              filters[key] = this.filters[key];
            }
          }
        });
        queryParams.filter = filters;
      }

      return queryParams;
    },
    onImportModalToggle(value) {
      this.importModal = value;
    },
    onPaginationChange(value) {
      this.options.page = value;
    },
    onFilterClick() {
      this.isFilterActive = !this.isFilterActive;
    },
    goToCase({ _id }) {
      this.$router.push(`cases/${_id}`);
    },
    itemRowBackground(item) {
      return item.status === CasesStatuses.groupReview
        ? "group-review-needed-status"
        : "";
    },
    async handleFilterChange(value, key) {
      this.filters[key] = value;
      this.options.page = 1;
    },
    async onFilterCancel() {
      Object.keys(this.filters).forEach((key) => (this.filters[key] = null));
      this.$refs.servicesInput.reset();
      await this.fetchCases();
    },
    async onCaseRemove() {
      if (!this.selectedCases.length) {
        return;
      }

      this.deleteCasesModal = true;
    },
    async onDeleteCases() {
      if (this.deleteCasesLoading) {
        return;
      }
      this.deleteCasesLoading = true;
      const cases = this.selectedCases.map((el) => el._id);

      try {
        await batchDeleteCases(cases);
        this.$notify({
          type: "success",
          text: "Cases deleted",
        });
        this.fetchCases();
      } catch (err) {
        this.$notify({
          type: "error",
          text:
            err?.response?.data?.message || err?.message || JSON.stringify(err),
        });
      }
      this.deleteCasesModal = false;
      this.deleteCasesLoading = false;
    },
    getServiceById(id) {
      const foundService = this.clinicalServices.find(
        (service) => id && service._id === id
      );
      return foundService;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";

.cases {
  .group-review-needed-status {
    background-color: #fff2f1;
  }

  &__header {
    &__filter-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--active {
        background-color: #f0f0f0;
        border-radius: 8px;
      }
    }

    &__delete-btn {
      min-width: 48px !important;
      height: 48px !important;

      &--disabled {
        cursor: not-allowed !important;
        opacity: 0.2;
      }
    }
  }

  &__filter-menu {
    & .app-container {
      height: 100vh;
      padding-top: #{$headerHeight};
    }
  }
}
</style>
